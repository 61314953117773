<template>
   <div class = "row mt-4">
        <img class = "col-sm-5" :src="require('@/assets/' + img + '')" alt="">
        <div class = "col-sm-6 mt-4">
            <h2 class = "mb-4">{{title}}</h2>
            <p class = "mt-4">{{text1}}</p>
        </div>
    </div> 
</template>

<script>
export default {
    props: ["title","text1","img"],
    data() {
        return {
        };
    },
};
</script>
