<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="INFORMACIÓN PÚBLICA PROGRAMA MOSCAMED" />
            <div class="text-center">
                <br />
                <img class="img-fluid" src="@/assets/logos/magagt.png" alt="" /><br /><br />
                <img class="img-fluid" src="@/assets/logos/ipl.jpg" alt="" />
            </div>

            <p class="mt-3">
                En cumplimiento de la «Ley de Acceso a la Información Pública» (Decreto Número 57-2008), EL Programa
                Moscamed presenta en su portal distintos enlaces, con el objeto de garantizar la transparencia de su
                administración de fondos públicos y el derecho de toda persona a tener libre acceso a dicha información.
            </p>

            <ul class="list-group">
                <li v-for="(seccion, index) in secciones" :key="index" class="list-group-item -flex bg-info">
                    <div v-if="seccion.subs">
                        <div class="row">
                            <div class="col-sm-1">
                                <span class="badge badge-info badge-pill">{{ seccion.punto }}</span>
                            </div>
                            <div class="col-sm-11 text-center text-white">
                                <strong>{{ seccion.titulo }}</strong>
                            </div>
                        </div>
                        <div class="row text-center bg-white p-2">
                            <div v-for="(sub, index) of seccion.subs" :key="index" class="col">
                                <template v-if="sub.require">
                                    <a
                                        :href="require('@/' + sub.archivo)"
                                        class="text-dark"
                                        target="blank"
                                        :data-fancybox="sub.fancy"
                                        :download="sub.download ? getNombre(sub.archivo) : null"
                                    >
                                        {{ sub.titulo }}
                                    </a>
                                </template>
                                <template v-else>
                                    <a
                                        :href="`${sub.archivo}`"
                                        class="text-dark"
                                        target="blank"
                                        :data-fancybox="sub.fancy"
                                        :download="sub.download ? getNombre(sub.archivo) : null"
                                    >
                                        {{ sub.titulo }}
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-sm-1">
                            <span class="badge badge-info badge-pill">{{ seccion.punto }}</span>
                        </div>
                        <div class="col-sm-11 text-center text-white">
                            <template v-if="seccion.require">
                                <a
                                    :href="require('@/' + seccion.archivo)"
                                    target="blank"
                                    :data-fancybox="seccion.fancy"
                                >
                                    <strong>{{ seccion.titulo }}</strong>
                                </a>
                            </template>
                            <template v-else>
                                <a :href="`${seccion.archivo}`" target="blank" :data-fancybox="seccion.fancy">
                                    <strong>{{ seccion.titulo }}</strong>
                                </a>
                            </template>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            secciones: [
                {
                    punto: "1",
                    titulo: "ESTRUCTURA ORGANICA",
                    archivo: "assets/quienesSomos/organigrama.png",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "1.1",
                    titulo: "FUNCIONES DE LAS DEPENDENCIAS",
                    archivo: "assets/infopublica/funciones.jpg",
                    require: true,
                    fancy: "gallery",
                },
                { punto: "1.2", titulo: "MARCO NORMATIVO - CONVENIO", archivo: "pdfs/Convenio-MOSCAMED.pdf" },
                { punto: "1.3", titulo: "MARCO NORMATIVO - DECRETOS", archivo: "pdfs/Marco-normativo-Decretos.pdf" },
                {
                    punto: "2",
                    titulo: "DIRECTORIO",
                    archivo: "assets/infopublica/directorio-de-la-identidad.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "3",
                    titulo: "DIRECTORIO DE EMPLEADOS",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/DirectorioEmpleados2020.xlsx", download: true },
                        { titulo: "2021", archivo: "pdfs/DirectorioEmpleados2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/DirectorioEmpleados2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/DirectorioEmpleados2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/DirectorioEmpleados2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "4",
                    titulo: "REMUNERACIONES",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/ListaPersonalRemuneraciones2020.xlsx", download: true },
                        { titulo: "2021", archivo: "pdfs/ListaPersonalRemuneraciones2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/ListaPersonalRemuneraciones2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/ListaPersonalRemuneraciones2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/RemuneracionesPersonal2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "5",
                    titulo: "MISION",
                    archivo: "assets/infopublica/mision.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "5.1",
                    titulo: "OBJETIVOS",
                    archivo: "assets/infopublica/objetivos.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "5.2",
                    titulo: "PLAN OPERATIVO ANUAL",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/POA2020.pdf" },
                        { titulo: "2021", archivo: "pdfs/POA2021.pdf" },
                        { titulo: "2022", archivo: "pdfs/POA2022.pdf" },
                        { titulo: "2023", archivo: "pdfs/POA2023.pdf" },
                        { titulo: "2024", archivo: "pdfs/POA2024.pdf" },
                    ],
                },
                {
                    punto: "5.3",
                    titulo: "RESULTADOS DEL POA",
                    subs: [
                        {
                            titulo: "2020",
                            archivo: "assets/infopublica/ResultadosPOA2020.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        { titulo: "2021", archivo: "pdfs/ResultadosPOA2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/ResultadosPOA2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/ResultadosPOA2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/ResultadosPOA2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "6",
                    titulo: "MANUALES",
                    subs: [
                        { titulo: "Administrativo", archivo: "pdfs/manualAdministrativo.pdf" },
                        { titulo: "Operativo", archivo: "pdfs/ManualOperativo.pdf" },
                    ],
                },
                {
                    punto: "7",
                    titulo: "PRESUPUESTO",
                    subs: [
                        {
                            titulo: "2020",
                            archivo: "assets/infopublica/PresupuestoOperativo2020.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        { titulo: "2021", archivo: "pdfs/PresupuestoOperativo2021.xls", download: true },
                        { titulo: "2022", archivo: "pdfs/PresupuestoOperativo2022.xls", download: true },
                        { titulo: "2023", archivo: "pdfs/PresupuestoOperativo2023.xls", download: true },
                        { titulo: "2024", archivo: "pdfs/PresupuestoOperativo2024.xls", download: true },
                    ],
                },
                {
                    punto: "7.1",
                    titulo: "PROGRAMAS PRESUPUESTARIOS",
                    archivo: "assets/infopublica/ProgramaPresupuestario.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "7.2",
                    titulo: "MODIFICACIONES Y TRANSFERENCIAS",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/Transferencias2020.pdf" },
                        { titulo: "2021", archivo: "pdfs/Transferencias2021.xls", download: true },
                        { titulo: "2022", archivo: "pdfs/Transferencias2022.xls", download: true },
                        { titulo: "2023", archivo: "pdfs/Transferencias2023.xls", download: true },
                        { titulo: "2024", archivo: "pdfs/Transferencias2024.xls", download: true },
                    ],
                },
                {
                    punto: "8",
                    titulo: "EJECUCION PRESUPUESTARIA",
                    subs: [
                        {
                            titulo: "2020",
                            archivo: "assets/infopublica/Presupuesto2020.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        { titulo: "2021", archivo: "pdfs/Presupuesto2021.xls", download: true },
                        { titulo: "2022", archivo: "pdfs/Presupuesto2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/Presupuesto2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/EjecucionPresupuestaria2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "9",
                    titulo: "DEPOSITOS CON FONDOS PUBLICOS",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/DepositosFondosPublicos2020.xlsx", download: true },
                        { titulo: "2021", archivo: "pdfs/DepositosFondosPublicos2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/DepositosFondosPublicos2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/DepositosFondosPublicos2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/DepositosFondosPublicos2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "10",
                    titulo: "COTIZACIONES Y LICITACIONES DE PROGRAMAS",
                    archivo: "assets/infopublica/ContratacionesLicitacionesPrgramas.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "11",
                    titulo: "CONTRATACION DE BIENES Y SERVICIOS",
                    archivo: "assets/infopublica/ContratacionBienesSevicios.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "12",
                    titulo: "VIAJES NACIONALES",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/ViajesNacionales2020.xlsx", download: true },
                        { titulo: "2021", archivo: "pdfs/ViajesNacionales2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/ViajesNacionales2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/ViajesNacionales2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/ViajesNacionales2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "12.1",
                    titulo: "VIAJES INTERNACIONALES",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/ViajesInternacionales2020.xlsx", download: true },
                        { titulo: "2021", archivo: "pdfs/ViajesInternacionales2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/ViajesInternacionales2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/ViajesInternacionales2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/ViajesInternacionales2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "13",
                    titulo: "INVENTARIO DE BIENES MUEBLES",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/inventarioBienesMuebles2020.xlsx", download: true },
                        { titulo: "2021", archivo: "pdfs/inventarioBienesMuebles2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/inventarioBienesMuebles2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/inventarioBienesMuebles2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/inventarioBienesMuebles2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "13.1",
                    titulo: "INVENTARIO DE BIENES INMUEBLES",
                    archivo: "assets/infopublica/InventarioBienesInmuebles.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "14",
                    titulo: "CONTRATOS DE MANTENIMIENTO",
                    archivo: "assets/infopublica/ContratoMantenimiento.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "15",
                    titulo: "SUBSIDIOS",
                    archivo: "assets/infopublica/Subsidios.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "15.1",
                    titulo: "BECAS",
                    archivo: "assets/infopublica/Becas.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "15.2",
                    titulo: "TRANSFERENCIAS",
                    archivo: "assets/infopublica/Transferencias2.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "16",
                    titulo: "CONTRATOS DE LICENCIAS Y CONCESIONES",
                    subs: [
                        { titulo: "2021", archivo: "pdfs/Usufructos 2021.xls", download: true },
                        { titulo: "2022", archivo: "pdfs/Usufructos2022.xls", download: true },
                        { titulo: "2023", archivo: "pdfs/Usufructos2023.xls", download: true },
                        { titulo: "2024", archivo: "pdfs/Usufructos2024.xls", download: true },
                    ],
                },
                {
                    punto: "17",
                    titulo: "EMPRESAS PRECALIFICADAS",
                    archivo: "assets/infopublica/EmpresasPrecalificadas.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "18",
                    titulo: "OBRAS EN EJECUCION O EJECUTADAS",
                    archivo: "assets/infopublica/ObrasEnEjecucion.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "19",
                    titulo: "CONTRATOS DE ARRENDAMIENTO",
                    subs: [
                        {
                            titulo: "2020",
                            archivo: "assets/infopublica/ContratosArrendamiento2020.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        {
                            titulo: "2021",
                            archivo: "assets/infopublica/ContratosArrendamiento2021.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        { titulo: "2022", archivo: "pdfs/ContratosArrendamiento2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/ContratosArrendamiento2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/ContratosArrendamiento2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "20",
                    titulo: "COTIZACIONES Y LICITACIONES",
                    subs: [
                        {
                            titulo: "2020",
                            archivo: "assets/infopublica/CotizacionesLicitaciones2019.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        { titulo: "2021", archivo: "pdfs/CotizacionesLicitaciones2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/CotizacionesLicitaciones2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/CotizacionesLicitaciones2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/CotizacionesLicitaciones2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "21",
                    titulo: "FIDEICOMISOS CON FONDOS PUBLICOS",
                    archivo: "assets/infopublica/FideicomisosConFondosPublicos.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "22",
                    titulo: "COMPRAS DIRECTAS",
                    subs: [
                        { titulo: "2020", archivo: "pdfs/ComprasDirectas2020.xls", download: true },
                        { titulo: "2021", archivo: "pdfs/ComprasDirectas2021.xlsx", download: true },
                        { titulo: "2022", archivo: "pdfs/ComprasDirectas2022.xlsx", download: true },
                        { titulo: "2023", archivo: "pdfs/ComprasDirectas2023.xlsx", download: true },
                        { titulo: "2024", archivo: "pdfs/ComprasDirectas2024.xlsx", download: true },
                    ],
                },
                {
                    punto: "23",
                    titulo: "AUDITORIAS",
                    subs: [
                        // { titulo: "MAGA", archivo: "pdfs/AuditoriaMAGA2020.pdf" },
                        { titulo: "CGC 2021", archivo: "pdfs/AuditoriaCGC.pdf" },
                        { titulo: "CGC 2023", archivo: "pdfs/AuditoriaCGC2023.pdf" },
                    ],
                },
                {
                    punto: "24",
                    titulo: "INFORMACION DE ENTIDADES INTERNACIONALES",
                    archivo: "assets/infopublica/InformacionEntidadesInternacionales.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "25",
                    titulo: "INFORMACION DE ENTIDADES NO GUBERNATIVAS O PRIVADAS",
                    archivo: "assets/infopublica/InfomacionEntidadesNoGubernamentalesOPrivadas.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "26",
                    titulo: "FUNCIONAMIENTO DE ARCHIVO",
                    subs: [
                        {
                            titulo: "2022",
                            archivo: "assets/infopublica/FuncionamientoDeArchivo2022.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        {
                            titulo: "2023",
                            archivo: "pdfs/FuncionamientoDeArchivo2023.pdf",
                            download: true,
                        },
                    ],
                },
                {
                    punto: "27",
                    titulo: "INDICE DE LA INFORMACION CLASIFICADA",
                    archivo: "pdfs/IndiceInformacionClasificada.pdf",
                },
                {
                    punto: "28",
                    titulo: "PERTENENCIA SOCIOLINGUISTICA",
                    subs: [
                        {
                            titulo: "2021",
                            archivo: "assets/infopublica/PertenenciaSociolenguistica2021.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        {
                            titulo: "2022",
                            archivo: "assets/infopublica/PertenenciaSociolenguistica2022.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        {
                            titulo: "2023",
                            archivo: "assets/infopublica/PertenenciaSociolenguistica2023.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                        {
                            titulo: "2024",
                            archivo: "assets/infopublica/PertenenciaSociolenguistica2024.jpg",
                            require: true,
                            fancy: "gallery",
                        },
                    ],
                },
                {
                    punto: "29",
                    titulo: "OTRA INFORMACION",
                    archivo: "assets/infopublica/OtraInformacion.jpg",
                    require: true,
                    fancy: "gallery",
                },
                {
                    punto: "29.1",
                    titulo: "ACUERDO DE CONFORMACION",
                    archivo: "assets/infopublica/AcuerdoConfirmacion.jpg",
                    require: true,
                    fancy: "gallery",
                },
                { punto: "29.2", titulo: "MEMORIA DE LABORES 2023", archivo: "pdfs/MemoriaLaboresMoscamed.pdf" },
            ],
        };
    },
    methods: {
        getNombre(archivo) {
            return archivo.split("/").slice(-1);
        },
    },
};

window.scrollTo(0, 0);
</script>
<style scoped>
a {
    color: white;
}
a:hover {
    text-decoration: none;
}

.badge-info {
    background: #007bff !important;
}

.bg-info {
    background: #4e80bd !important;
}

.subsection {
    background: #c5d8f1 !important;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgb(255 255 255);
}
</style>
