<template>
    <div>
        <div class = "w-100 pt-5 pb-5" style="background: #bfbfbf;">
            <h4 class="text-center position-relative" style="left:70%; color: rgb(84, 101, 54); width:max-content;">{{titulo}}</h4>
        </div>
    </div>
</template>

<script>
export default {
    props: ["titulo"],
    data() {
        return {
        };
    },
};
</script>