<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="SINACIG" /><br />
            <div class="row text-center pt-5 pb-5">
                <div v-for="(document, index) in documents" :key="index" class="col">
                    <a :href="`pdfs/` + document.pdf" target="blanck">
                        <div class="col">
                            <img class="img-fluid pb-3" :src="require('@/assets/sinacig/' + document.portada)" alt="" />
                            <p>
                                <strong>{{ document.decripcion }}</strong>
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            documents: [
                // {
                //     pdf: "informeAnualControlInterno2023.pdf",
                //     portada: "informeAnualControlInterno2023.png",
                //     decripcion: "INFORME ANUAL DE CONTROL INTERNO 2023",
                // },
                // {
                //     pdf: "informeAnualControlInterno2023.pdf",
                //     portada: "informeAnualControlInterno2022.png",
                //     decripcion: "INFORME ANUAL DE CONTROL INTERNO 2022",
                // },
            ],
        };
    },
};
</script>
<style scoped>
a {
    color: black !important;
}
</style>
